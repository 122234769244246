import React, { useState } from 'react';
import { graphql } from 'gatsby'

import NavBar from '../components/NavBar';
import Head from '../components/Head';

import '../scss/reel.scss';

const Reel = (props) => {
    const bgVideo = props.data.allContentfulReel.nodes[0].videoLink

    const [navOpen, setNavOpen] = useState(false);
    const navCallback = (state) => {
        setNavOpen(state);
    }

    return (
        <div className='reel'>
            <Head url='https://northboundfilms.com/reel'></Head>
            <div className='nav-container'>
                <NavBar parentCallback={navCallback} />
                <div className={(navOpen ? 'disabled-content' : null)} />
                <div className={`centered2 transition mobile-margin-top ${(navOpen ? 'shrink' : null)}`}>
                    <div>
                        <div className='heading'>
                            <h1 className='header'>Our Reel</h1>
                            <h2 className='subheader'>Watch our compilation</h2>
                        </div>
                    </div>
                    <div className='centered3'>
                        <div id='video-background'>                 
                            <iframe className='iframe' frameBorder='0' src={`${bgVideo}?title=0`} width='100%' height='100%'/>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default Reel;

export const query = graphql`
    query {
        allContentfulReel {
            nodes {
                videoLink
            }
        }
    }
`